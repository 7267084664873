// React
import React from "react";

const FeatureCard = (props) => (
  <li className={!props.top ? "mt-10" : {}}>
    <div className="flex">
      <div className="flex-shrink-0">
        <div className="flex items-center justify-center h-12 w-12 rounded-md bg-blue-900 text-white">
          {props.svg}
        </div>
      </div>
      {props.description ? (
        <div className="ml-4">
          <h5 className="text-lg leading-6 font-medium text-gray-900">
            {props.header}
          </h5>
          <p className="mt-2 text-base leading-6 text-gray-500">
            {props.description}
          </p>
        </div>
      ) : (
        <div className="ml-4 flex items-center">
          <h5 className="text-lg leading-6 font-medium text-gray-900">
            {props.header}
          </h5>
        </div>
      )}
    </div>
  </li>
);

export default FeatureCard;
